<template>
  <div class="session-bg purple darken-3">
    <div class="login-container">
      <base-card>
        <v-row class="align-center">
          <v-col
            cols="12"
            md="6"
            class="pa-md-10 col-md-6 col-12 text-center"
          >
            <img
              class=""
              src="@/assets/images/lock.jpeg"
            >
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="bg-gray-300"
          >
            <div class="px-4 mt-0 mt-sm-3 pb-3 pb-sm-0">
              <v-form
                ref="form"
                v-model="validForm"
                lazy-validation
              >
                <h5 class="text-center mb-5">
                  {{ $t("userAuth.login") }}
                </h5>
                <v-text-field
                  v-model="userEmail"
                  :label="`${$t('common.email')}*`"
                  type="email"
                  :rules="emailRules"
                />
                <v-text-field
                  v-model="userPassword"
                  :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isShowPassword ? 'text' : 'password'"
                  :label="`${$t('common.password')}*`"
                  :rules="passwordRules"
                  @click:append="isShowPassword = !isShowPassword"
                />

                <v-alert
                  border="right"
                  colored-border
                  type="error"
                  elevation="2"
                  class="mt-2 text-left"
                  dismissible
                  v-if="errorMessage"
                >
                  {{ errorMessage }}
                </v-alert>

                <div class="d-flex align-center mt-md-4 justify-center">
                  <v-btn
                    depressed
                    color="primary"
                    :disabled="!validForm"
                    :loading="isLoading"
                    @click="submitForm"
                  >
                    {{ $t("userAuth.signIn") }}
                  </v-btn>
                  <template v-if="isRegisterAvailable">
                    <p class="mx-4 mb-0 text-18 font-weight-bold">
                      {{ $t("userAuth.or") }}
                    </p>
                    <v-btn
                      depressed
                      color="danger"
                      class="white--text"
                      to="/signup"
                    >
                      {{ $t("userAuth.signUp") }}
                    </v-btn>
                  </template>
                </div>

                <div class="pt-4 text-center">
                  <v-btn text small class="font-weight-bold text-subtitle-2" to="/forgot-password" color="purple">
                    {{ $t("userAuth.forgotPassword") }}
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </base-card>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'

  export default {
    name: 'Login',

    metaInfo: {
      title: 'Login',
    },

    data () {
      return {
        isShowPassword: false,
        userEmail: '',
        userPassword: '',
        validForm: false,
        isLoading: false,
        emailRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.email') }),
          (v) => /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
        passwordRules: [
          (v) => !!v || this.$t('error.fieldRequired', { field: this.$t('common.password') }),
        ],
        errorMessage: '',
      }
    },

    computed: {
      isRegisterAvailable() {
        const origin = window.location.origin

        if (process.env.NODE_ENV === 'development') {
          return true
        } else if (origin === process.env.VUE_APP_SERVER_URL) {
          return false
        }

        return true
      },
    },

    watch: {
      userEmail() {
        this.errorMessage = ''
      },

      userPassword() {
        this.errorMessage = ''
      },
    },

    mounted () {
      const accessToken = localStorage.getItem('access_token') || ''

      if (accessToken) {
        this.$router.push('/partner-price')
      }
    },

    methods: {
      ...mapActions({
        login: 'login',
      }),

      async submitForm () {
        const isValid = this.$refs.form.validate()
        this.errorMessage = ''

        if (!isValid) {
          return
        }

        this.isLoading = true
        try {
          const response = await this.login({
            email: this.userEmail,
            password: this.userPassword,
          })

          if (response.token) {
            localStorage.setItem('access_token', response.token)
            localStorage.setItem('role', response.role)
            window.location.href = '/partner-price'
          } else {
            window.location.href = response.partnerUrl
          }
        } catch (error) {
          this.errorMessage = util.getErrorResponse(error)
        }
        this.isLoading = false
      },
    },
  }
</script>

<style lang="scss">
.session-bg {
  height: 100vh;
  align-items: center;
  place-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 40px 1rem;
}
.login-header img {
  width: 200px;
}
.login-container img {
  max-height: 300px;
  max-width: 100%;
}
@media only screen and (min-width: 1024px) {
  .login-container {
    max-width: 600px;
  }
}
</style>
